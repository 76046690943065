import React, { useState } from "react";
import moment from "moment";
import Search from "../../../../components/search";
import { filterList } from "../data";
import { useSelector } from "react-redux";
import Sorting from "../../../../components/sorting";
import Pagination from "../../../../components/tablePagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import Button from "../../../../components/button";
import { fireFilter } from "../functions";
import CsvDownload from "../../../../components/csvdownload";
import { session_id_beg, session_id_end } from "../../../../utils/helpers";
import Filter from "../../../../components/filter";
import UpdateAppointments from "../../Subcomponents/updateappt";
import CopyComponent from "../../../../components/copyComponent";
const headers = [
  { label: "Medium Name", key: "medium_name" },
  { label: "Client Name", key: "client_name" },
  { label: "Date", key: "date" },
  { label: "Time", key: "time" },
  { label: "Session Name", key: "session_type" },
  // { label: "Emails", key: "emails" },
  // { label: "Meeting Link", key: "meeting_link" },
  // { label: 'Feedback Links', key: "" },
  { label: "Date and Time Cancelled", key: "date_time_canceled" },
  // { label: "Cancelled By", key: 'userby' },
  { label: "No Show By", key: "userby" },
];

export default function NoShows(props) {
  const {
    showAppointment,
    filterListAppointments,
    getPaginationData,
    setFilterValues,
    setSearchValues,
    filterReset,
    noshowAppointmentsPage,
    noshowAppointmentsrowsPerPage,
    searchValue,
    filterValues,
    serviceTypeList,
    changeHandlerFilter,
    serviceFilterValue,
    setSortingNameOrderBy,
    setSortingTabType,
    getAllAppointmentsList,
    activeTab,
    setOptionsList,
    optionsList,
    operatorList,
    setOperatorList,
    showOprater,
    setShowOprater,
    setFilterOprater,
    filterOprater,
    setFilterValueOptions,
    filterValueOptions,
    setServiceTypeShow,
    serviceTypeShow,
  } = props;
  // const copyMeetingLink = (url) => {
  //   navigator.clipboard.writeText(url);
  // };
  const { allNoShowCount } = useSelector((state) => state.users);
  const [updateItem, setUpdateItem] = useState({});
  const navigate = useNavigate();


  const openUpdateappointmentBox = (item) => {
    setUpdateItem(item)
    window.loadModal2('#UpdateAppointments', 'show')
  }

  const getAppointmentsNewList = () => {

    const info = {
      page: noshowAppointmentsPage,
      rowsPerPage: noshowAppointmentsrowsPerPage,
    };
    getAllAppointmentsList(info, activeTab)
    window.loadModal2('#UpdateAppointments', 'hide')
  }
  let info = JSON.parse(localStorage.getItem('pagination'))
  const navigateEdit = (e, id) => {
     window.renderFirst = false
     console.log(e.target.className);
    if (e.target.className == "capitalize_medium") {
       navigate(`/profile-edit/${id.medium_id}`);
    } 
    if(e.target.className == "capitalize_client"){
      if(id.client_user_type == 3){
        navigate(`/edit/${id.client_id}`);
      }else{
        navigate(`/profile-edit/${id.client_id}`);
      }
    }  
  };
  const checkAppId = (appoinment) => {
    if (appoinment.is_group_session === 0) {
      return appoinment.id
    }
    else if (appoinment.is_group_session === 1) {
      if (appoinment.attendees == undefined) {
        if (appoinment.parent_id == null) {
          return appoinment.id
        }
        else {
          return appoinment.parent_id
        }

      }
      else {
        return appoinment.id
      }

    }
    else {
      return appoinment.id
    }

  }
  return (
    <>
      <div className="d-flex justify-content-end gap-10 flex-wrap">
        <Search
          callApi={filterListAppointments}
          list={filterList}
          setSearchValues={setSearchValues}
          setFilterValues={setFilterValues}
          searchValue={searchValue}
          filterValues={filterValues}
          setOptionsList={setOptionsList}
          optionsList={optionsList}
          operatorList={operatorList}
          setOperatorList={setOperatorList}
          showOprater={showOprater}
          setShowOprater={setShowOprater}
          setFilterOprater={setFilterOprater}
          filterOprater={filterOprater} setFilterValueOptions={setFilterValueOptions} filterValueOptions={filterValueOptions} isOperator={1} isDatepickerShow={1} isAppointment={1} setServiceTypeShow={setServiceTypeShow}
          serviceTypeShow={serviceTypeShow}
        />
        {serviceTypeShow && (
        // <Filter
        //   list={serviceTypeList}
        //   changeHandler={(e) => changeHandlerFilter(e, 'noshows')}
        //   filterValue={serviceFilterValue}
        //   name={'Service Type'} />
          <Select
            id="serviceType"
            name={'Service Type'} 
            className="selection-box"
            options={serviceTypeList}
            multi={true}
            isMulti
            onChange={(value) => changeHandlerFilter(value, 'noshows')}
            placeholder="Service Type"
            value={serviceFilterValue}
            closeMenuOnSelect={false}
            closeMenuOnScroll={false}
        />
        )}
        {/* <Button
          status={0} 
           type="button"
           onClick={filterReset}
           className={`resend-btn  width-80px  reset-btn-123`}
           title="Reset"
          /> */}
      </div>
      <CsvDownload
        href={
          `${process.env.REACT_APP_API_URL_EXPORT}` +
          "export-appoitment-list?appoitment_type=show"
        }
      // usertype={"show"}
      // data={fireFilter(showAppointment)} headers={headers} filename={'noshows'}
      />
      <div className="table-box-medium admin-appoi overflow-scroll">
        <table className="caseTable">
          <tbody>
            <tr className="dark">
              <th className="min-width-100"> Session #</th>
              <th>
                {/* <input type="checkbox" id="one" />
                <label htmlFor="one"></label> */}
                Medium Name
              </th>
              <th>Client Name</th>
              <th>Session Date</th>
              <th>
                {" "}
                <div className="d-flex align-items-center">
                  {" "}
                  <span>Time</span> <Sorting tab={4} callApi={filterListAppointments} view={'admin'} types={'time'} setSortingNameOrderBy={setSortingNameOrderBy}
                    setSortingTabType={setSortingTabType} />
                </div>
              </th>

              <th>Session Name</th>
              {/* <th>Meeting Link</th> */}
              <th>Date and Time Cancelled</th>
              <th>No Show By</th>
              <th>Status</th>
              <th><div className="d-inline-flex align-items-center align-items-center">
                <span> Created at</span>{" "}
                <Sorting tab={4} callApi={filterListAppointments} view={'admin'} types={'created_at'} setSortingNameOrderBy={setSortingNameOrderBy}
                  setSortingTabType={setSortingTabType} />
              </div></th>
              {/* <th>Action</th> */}
            </tr>
            {showAppointment.length > 0 ? (
              showAppointment.map((appoinment) => (
                <tr onClick={(e) => navigateEdit(e, appoinment)} className="innr">
                  {/* <td>{session_id_beg + checkAppId(appoinment) + session_id_end}</td> */}
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="mr-1 mt-1"><CopyComponent value={session_id_beg + checkAppId(appoinment) + session_id_end} /></div>
                      <div className="mt-1">{session_id_beg + checkAppId(appoinment) + session_id_end}</div>
                    </div>
                  </td>
                  <td className="pointer">
                  
                    <span className="capitalize d-flex"><div className="capitalize_medium"> {appoinment.medium_name}</div>&nbsp;<div className="capitalize_medium">({appoinment.medium_email})</div>&nbsp;{appoinment.medium_comment === true && (<b style={{
                      fontSize: '22px',
                    }}>*</b>)}<CopyComponent value={appoinment.medium_email} /> </span>

                  </td>
                  <td className="pointer">
                    <span className="capitalize d-flex"><div className="capitalize_client">{appoinment.client_name}</div>&nbsp;<div className="capitalize_client">({appoinment.client_email})</div>&nbsp;{appoinment.client_comment === true && (<b style={{
                      fontSize: '22px',
                    }}>*</b>)}<CopyComponent value={appoinment.client_email} /></span>
                  </td>
                  {/* <td>
                   
                    <span className="capitalize">{appoinment.medium_name}&nbsp;({appoinment.medium_email}) </span>
                  </td>
                  <td>
                    <span className="">{appoinment.client_name}&nbsp;({appoinment.client_email})</span>
                    <>{appoinment.client_comment === true && (<b style={{
                      fontSize: '22px',
                    }}>*</b>)}</>
                  
                  </td> */}
                  <td>{appoinment.date}</td>
                  <td>
                    {appoinment.time}{" "}
                    {/* {moment.utc(appoinment.time, ["h:mm A"]).local().format("hh:mm a")} */}
                  </td>
                  <td className="width-190px ">{appoinment.session_name}</td>
                  <td>{appoinment.modifiedDate}</td>
                  <td>{appoinment.userby}</td>
                  <td>

                    <Button
                      status={0}
                      onClick={(e) => openUpdateappointmentBox(appoinment)}
                      type="button"
                      className={`resend-btn w-130`}
                      title="Change Status"
                    />

                  </td>
                  <td>{appoinment.createdDate}</td>
                  {/* <td>
                    <div>
                      <p className="d-flex">
                        <div> Client:</div>{" "}
                        <div className="d5a67b ml-1">
                          {appoinment.client_email}{" "}
                        </div>
                      </p>
                      <p className="d-flex">
                        {" "}
                        <div>Medium:</div>{" "}
                        <div className="d5a67b ml-1">
                          {appoinment.medium_email}
                        </div>
                      </p>
                    </div>
                  </td> */}
                  {/* <td>Practice - 60 Min (30 Min each)</td> */}
                  {/* <td className="googl-link">
                    <a
                      className="appointment-links"
                      href={appoinment.meeting_link}
                      target="_blank"
                    >
                      {appoinment.meeting_link}
                    </a>
                    <img
                      onClick={() => copyMeetingLink(appoinment.meeting_link)}
                      className="googl-link-copy-img "
                      alt="copy"
                      src="images/copy.png"
                    />
                  </td> */}

                  {/* <td className="change">
                    <a href="#">
                      <img src="../images/email-blu.png" />
                      Email
                    </a>
                  </td> */}
                  {/* <td className="dot-image">
                    <img src="../images/Unionblck.png" />
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Appointments Found
                </td>
              </tr>
            )}

          </tbody>
        </table>
        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          count={allNoShowCount}
          activeTab={"noshowsAdmin"}
          getPaginationData={getPaginationData}
          noshowAppointmentsPage={noshowAppointmentsPage}
          noshowAppointmentsrowsPerPage={noshowAppointmentsrowsPerPage}
        />
        <UpdateAppointments
          updateItem={updateItem}
          appType={['Complete', 'Cancel', 'Restrict', 'Ended Early']}
          from="noshow"
          getAppointmentsNewList={getAppointmentsNewList}
        />
      </div>
    </>
  );
}
