import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { setFeedBacks } from "../../../../reducer/mediumSlice";
import { setLoading } from "../../../../reducer/auth/auth";
import { getFeedbacks, publishFeedback } from "../../../../services/medium";
import InfoPopup from "../../../../components/infopopup";
import FeedBackTimeBox from "../subcomponents/feedbacktimebox";
import Button from "../../../../components/button";
import "../index.css";
// import "./feedback.css";
import { toast } from "react-toastify";

export default function AllFeedback() {
  const { feedBacks } = useSelector((state) => state.medium);
  const dispatch = useDispatch();
  // const [activeTab, setActiveTab] = useState(0);
  const [localFeedBacks, setLocalFeedBacks] = useState([]);
  const [active, setActive] = useState({});
  const [publishActiveItem, setPublishActiveItem] = useState()
  const [localFeedBacksstatus, setLocalFeedBacksstatus] = useState([]);
  const [sessionType, setSessionType] = useState([])


  useEffect(() => {
    if (!feedBacks.length) callBack();
  }, []);
  useEffect(() => {
    let info = feedBacks.map((data) => {
      return false;

    })
    setLocalFeedBacksstatus(info);
    setLocalFeedBacks(feedBacks);
  }, [feedBacks]);

  function groupBy(objectArray, property) {
    const grouped = new Map();
    
    objectArray.forEach((item) => {
        const key = item[property];
        if (!grouped.has(key)) {
            grouped.set(key, []);
        }
        grouped.get(key).push(item);
    });
    
    // Convert Map to array of arrays, maintaining order
    return Array.from(grouped.values());
    
  }

  const callBack = () => {
    getFeedbacks()
      .then((data) => {
        if (data.data.status) {

          let info = []
          console.log(typeof (info))
          if (typeof (info) === 'object') {
            const propertyValues = Object.values(data.data.data);
            info = propertyValues.map((feed) => {
              delete feed.feedback['about_verysoul'];
              return feed;
            })
          }
          else {
            info = data.data.data.map((feed) => {
              delete feed.feedback['about_verysoul'];
              return feed;
            })
          }
          let grouped = groupBy(info, "appointment");
          console.log(grouped,"grouped");
          console.log(info,"info");
          // let array = [];
          let obj = Object.values(grouped).map((val) => {
            return Object.assign({}, val);
          });
          
          dispatch(setFeedBacks(obj));
        }
      })
      .catch(() => { });
  };

  const openMoreInfo = (info) => {
    let array = [];

    var dataSession = info;
    var coachingCheck = info && info[0].session_type;
    setSessionType(dataSession);
    if (coachingCheck == "Coaching") {
      var feedback = info && info[0].feedback ? info[0].feedback : "";
      if (feedback) {
        const arrayData = Object.keys(dataSession).map(key => dataSession[key]);
        setSessionType(arrayData);
        array = [{
          '1. What do you want other Mediums to know about this coach?': feedback && feedback.answer_1 ? feedback.answer_1 : "",
          '2. What 3 words best describe this coach?': "",
          '2.1': feedback && feedback.answer_21 ? feedback.answer_21 : "",
          '2.2': feedback && feedback.answer_22 ? feedback.answer_22 : "",
          '2.3': feedback && feedback.answer_23 ? feedback.answer_23 : "",
          '3. How did this session help advance your mediumship?': feedback && feedback.answer_3 ? feedback.answer_3 : "",
          '4. Any additional private message to your coach?': feedback && feedback.answer_4 ? feedback.answer_4 : "",
        }];
        setActive(array);
      }
    } else {
      Object.values(info).map((item) => {
        array.push(item.feedback);
      });
      setActive(array);
    }
    window.loadModal2("#infopopup", "show");
  };
  const updatePublishStatus = (id, status, duration = "") => {
    dispatch(setLoading(true));
    let body = {
      feedback_id: id,
      status: status,
      // duration: duration,
    };
    publishFeedback(body)
      .then((data) => {
        dispatch(setLoading(false));
        if (data.data.status && status === 0) {

          toast.success("Feedback Consider Succesfully", {
            position: "top-center",
          });

        }
        if (data.data.status && status === 1) {
          window.loadModal2("#feedbacktimebox", "hide");
          toast.success("Publish Requested Succesfully", {
            position: "top-center",
          });

        }
        callBack()
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const openApproveContainer = (item) => {
    setPublishActiveItem(item);
    window.loadModal2("#feedbacktimebox", "show");
  };
  const onClickHandler = (e, index) => {
    let info = localFeedBacksstatus.map((data, i) => {
      if (i === index) {
        return !data;
      }
      else {
        return false;
      }
    })

    setLocalFeedBacksstatus(info);
  }
  return (
    <>
      <div className="dashboard">
        <div className="row">
          <div className="col-md-12">
            <div className="my-ppointments-box appoint-das">
              <div className="table-box-medium admin-feed-page">
                <table className="caseTable">
                  <tbody>
                    <tr className="dark">
                    <th>Session #</th>
                      <th>Client Name</th>
                      <th>Feedback</th>
                      <th>Review Status</th>
                      <th>Session Date</th>
                      <th>Session Time</th>
                      <th>Survey Date</th>
                      <th>Status</th>
                    </tr>
                    {localFeedBacks.length > 0 ? (
                      localFeedBacks.map((item, index) => (
                        <tr className="innr">
                          <td>{item[0].front_appointment_id}</td>
                          <td className="">{item[0].client_name}</td>
                          <td className=""><button 
                            className="font-size-14 mr-3 pointer learn-more-btn btnView"
                            onClick={() => openMoreInfo(item)}
                          >
                            View
                          </button></td>
                          <td className="">
                          <div className="">
                                {item[0] && item[0].status === 0 && item[0].feedback_id ? (
                                  <select 
                                    className="appointment-select-box"
                                    onChange={(e) => updatePublishStatus(item[0].feedback_id, parseInt(e.target.value), "")}
                                    defaultValue=""
                                  >
                                    <option value="" disabled>Select</option>
                                    <option value="1">Approve</option>
                                    <option value="2">Consider</option>
                                  </select>
                                ) : (
                                  <>No actions available</>
                                )}
                           
                            
                                {/* {item[0].status !== 0 && <span
                                 onClick={() =>
                                  updatePublishStatus(item[0].feedback_id, 3, "")
                                }
                                >
                                  Request Review
                                </span>} */}
                                

                              </div>
                          </td>
                          <td className="">
                            {moment(item[0].session_date).format("LL")}
                          </td>
                          <td className="">
                            {/* {item[0].session_time} */}
                            {moment(item[0].session_time, ["h:mm A"]).format(
                              "hh:mm a"
                            )}
                          </td>
                          {/* <td className="">{item[0].session_type}</td> */}
                          
                          <td>
                            {moment(item[0].date).format("LL")}
                          </td>
                          <td>
                            {item[0].status === 0 ? 'Pending' : item[0].status === 1 ? 'Approved' : 'Considered'}
                          </td>

                          {/********** */}
                          {/* <td className="feedBack-action-box-medium">
                            <Button
                              className={`resend-btn w-unset height-35 min-width-130px`}
                              title="Approve"
                              status={0}
                              onClick={() => updatePublishStatus(item[0].feedback_id, 1, "")}
                            />
                            <Button
                              className={`resend-btn w-unset height-35 min-width-130px`}
                              title="Consider"
                              status={0}
                              onClick={() =>
                                updatePublishStatus(item[0].feedback_id, 0, "")
                              }
                            />
                            <img
                              title="More"
                              onClick={() => openMoreInfo(item)}
                              className="pointer"
                              src="/localImages/openeye.svg"
                              alt="eye"
                            />
                          </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12} className="text-center">
                          No Feedbacks Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <InfoPopup feedBackinfo={active} sessionType={sessionType} />
        <FeedBackTimeBox publishMediumFeedback={updatePublishStatus} info={publishActiveItem} />
      </div>
    </>
    // <div className="all-medium-page">
    //   <Tabs list={list} setActiveTab={setActiveTab} />
    //   <ul className="tabs-content">
    //     <TabPanel id={0} activeTab={activeTab}>
    //       <IntermediateFeedback />
    //     </TabPanel>
    //     <TabPanel id={1} activeTab={activeTab}>
    //       <PartnerFeedback />
    //     </TabPanel>
    //     <TabPanel id={2} activeTab={activeTab}>
    //       <ProfessionalFeedback />
    //     </TabPanel>
    //   </ul>
    // </div>
  );
}